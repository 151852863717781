<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Materiais Detalhados
    </v-card-title>
    <master-detail
      asyncExportType="materiais"
      :canDelete="false"
      :canEdit="false"
      :cols="colunas"
      hasBeneficioFilter
      :hasExportCSV="false"
      hasMonthlyFilter
      :hasNewButton="false"
      :opts="opts"
      :resourceUrl="resourceUrl"
      :serverPagination="true"
      @click="rowClick"
    >
      <div class="d-flex">
        <calculation-status-menu
          beneficio
          class="table-v-action-button mr-3"
          icon="mdi-alpha-m-box-outline"
          name="Status de Materiais"
          type="materiais"
        ></calculation-status-menu>
      </div>
    </master-detail>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { AnyClient } from '@/core/enums/user-types';

export default {
  components: {
    "calculation-status-menu": () => import("@/components/calculation-status-menu.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'user']),
    isClient() {
      return AnyClient.includes(this.user.tipo_usuario);
    },
    colunas: function () {
      return [
        { name: "NF", key: "nf", filterable: true },
        { name: "Data", key: "data", type: this.$fieldTypes.DATE },
        {
          name: "Competência",
          key: "mes_base",
          type: this.$fieldTypes.MONTH,
          width: 205,
        },
        {
          name: "Razão Social",
          key: "razao_social",
          width: 300,
          filterable: true,
        },
        {
          name: "CNPJ",
          key: "cnpj",
          width: 145,
          type: this.$fieldTypes.CNPJ,
        },
        { name: "Enquadramento", key: "enquadramento", width: 160 },
        {
          name: "Valor Total NF",
          key: "total_nf",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          width: 145,
        },
        {
          name: "Descrição do item",
          key: "descricao",
          width: 400,
        },
        { name: "Origem", key: "origem" },
        { name: "Quantidade", key: "quantidade" },
        {
          name: "Valor Utilizado P&D",
          key: "valor",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          width: 180,
        },
        { name: "Projeto", key: "projeto", width: 300, filterable: true, },
        { name: "Classificação", key: "finalidade", width: 300 },
        { name: "Justificativa", key: "justificativa" },
        { name: "P&D", key: "ped" },
        {
          key: "jobId",
          name: "ID Importação",
        },
      ];
    },
    resourceUrl: function () {
      return `/v1/fiscal/nfmaterial/${this.clientId}/detalhado`;
    },
  },
  methods: {
    rowClick: function ({ nfId, itemId, criterio_rateio_value }) {
      if (this.isClient) return;
      let routeData = this.$router.resolve({
        name: "dispendio-materiais-detalhes",
        params: { nfId },
        query: {
          itemId,
          criterio_rateio: criterio_rateio_value,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  data: function () {
    return {
      opts: {
        criterio_rateio: {
          1: "Interno",
          2: "Terceiro",
        },
      },
    };
  },
};
</script>
